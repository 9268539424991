@media (min-width: 900px) {
    aside {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 18%;
        padding: 0 !important;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #f1f0f0;

        .image {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: var(--primaryColor);
            width: 100%;
            // padding: 30px 30px 30px 0 !important;

            img {
                width: 100%;
                max-width: 120px;
                height: auto;
                padding: 16% 0;
            }

            a {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                text-transform: capitalize;
                text-decoration: none;
                padding: 15px !important;
                color: #fff;
                font-weight: bold;
                width: 100%;

                span {
                    padding: 0 10px;
                    color: #fff;
                }
            }
        }

        .active {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 0 0 0 15px;
            background-color: var(--primaryColor);

            a {
                text-transform: capitalize;
                text-decoration: none;
                padding: 15px 15px 15px 0 !important;
                color: var(--primaryColor);
                background-color: #f1f0f0;
                font-weight: bold;
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;

                span {
                    padding: 0 10px;
                    color: var(--primaryColor);
                }
            }
        }

        .details {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: var(--primaryColor);
            // padding: 30px 0 30px 0 !important;

            .inactive {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                a {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    text-transform: capitalize;
                    text-decoration: none;
                    padding: 15px !important;
                    color: #fff;
                    font-weight: bold;
                    width: 100%;

                    span {
                        padding: 0 10px;
                        color: #fff;
                    }
                }
            }

            .orgData {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                padding-bottom: 20px;

                p {
                    text-align: center;
                    color: white;
                    font-weight: 700;
                }
            }
        }
    }
    .notificationIcon {
        display: flex;
        align-items: center;
        position: relative;
        width: 100% !important;
        box-sizing: border-box;
        margin-left: -15px;
        padding-left: 10px;
        white-space: nowrap;
        justify-content: center;
        div{
            width: 150px !important;
            span {  
                    svg {
                        width: 20px !important;
                        height: 20px !important;
                    }
                }
        }
     
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    aside {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 18%;
        padding: 0 !important;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #f1f0f0;

        .image {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: var(--primaryColor);
            width: 100%;
            // padding: 30px 30px 30px 0 !important;

            img {
                width: 100%;
                max-width: 120px;
                height: auto;
                padding: 16% 0;
            }

            a {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                text-transform: capitalize;
                text-decoration: none;
                padding: 15px !important;
                color: #fff;
                font-weight: bold;
                width: 100%;

                span {
                    padding: 0 10px;
                    color: #fff;
                }
            }
        }

        .active {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 0 0 0 15px;
            background-color: var(--primaryColor);

            a {
                text-transform: capitalize;
                text-decoration: none;
                padding: 15px 15px 15px 0 !important;
                color: var(--primaryColor);
                background-color: #f1f0f0;
                font-weight: bold;
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;

                span {
                    padding: 0 10px;
                    color: var(--primaryColor);
                }
            }
        }

        .details {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: var(--primaryColor);
            // padding: 30px 0 30px 0 !important;

            .inactive {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                a {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    text-transform: capitalize;
                    text-decoration: none;
                    padding: 15px !important;
                    color: #fff;
                    font-weight: bold;
                    width: 100%;

                    span {
                        padding: 0 10px;
                        color: #fff;
                    }
                }
            }

            .orgData {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 100%;

                p {
                    text-align: center;
                    color: white;
                    font-weight: 700;
                }
            }
        }
    }
    .notificationIcon {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative !important;
        width: 100%;
        box-sizing: border-box;
        margin-left: -15px;
        padding-left: 10px;
        white-space: nowrap;
        div{
            width: 150px !important;
        span {
            svg {
                width: calc(0.9em + 0.6vw);
                height: calc(0.9em + 0.6vh);
            }
        }
    }
        .notificationCount {
            position: absolute;
            // top: 4px;
            // left: 15px;
            color: #fff;
            background-color: var(--primaryColor);
            border-radius: 50%;
            padding: 0 !important;
            margin: 0 !important;
            // margin: auto;
            text-align: center;
            width: 23px;
            font-size: 12px;
            font-weight: bold;
            padding: 2px 5px !important ;
        }
    }
}
@media (max-width: 899px) {
    .notificationIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative !important;
        width: 100%;
        box-sizing: border-box;
        margin-left: -15px;
        padding-left: 10px;
        white-space: nowrap;
        div{
            width: 150px !important;
        span {
            svg {
                width: calc(0.9em + 0.6vw);
                height: calc(0.9em + 0.6vh);
            }
        }
    }
        .notificationCount {
            position: absolute;
            // top: 4px;
            // left: 15px;
            color: #fff;
            background-color: var(--primaryColor);
            border-radius: 50%;
            padding: 0 !important;
            margin: 0 !important;
            // margin: auto;
            text-align: center;
            width: 23px;
            font-size: 12px;
            font-weight: bold;
            padding: 2px 5px !important ;
        }
    }

    aside {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 15%;
        padding: 0 !important;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #f1f0f0;

        .image {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: var(--primaryColor);
            width: 100%;
            // padding: 30px 0 30px 0 !important;

            img {
                width: 100%;
                max-width: 120px;
                height: auto;
                padding: 16% 0;
            }

            a {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                text-transform: capitalize;
                text-decoration: none;
                padding: 15px !important;
                color: #fff;
                font-weight: bold;
                width: 100%;

                span {
                    padding: 0 10px;
                    color: #fff;

                    svg {
                        width: 4vw;
                        height: 4vh;
                    }
                }

                .linkName {
                    display: none;
                }
            }
        }

        .active {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 0 0 0 15px;
            background-color: var(--primaryColor);
            width: 75%;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: capitalize;
                text-decoration: none;
                padding: 15px !important;
                color: var(--primaryColor);
                background-color: #f1f0f0;
                font-weight: bold;
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;

                span {
                    padding: 0 10px;
                    color: var(--primaryColor);

                    svg {
                        width: 4vw;
                        height: 4vh;
                    }
                }

                .linkName {
                    display: none;
                }
            }
        }

        .details {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: var(--primaryColor);
            // padding: 30px 0 30px 0 !important;

            .inactive {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                a {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    text-transform: capitalize;
                    text-decoration: none;
                    padding: 15px !important;
                    color: #fff;
                    font-weight: bold;
                    width: 100%;

                    span {
                        padding: 0 10px;
                        color: #fff;

                        svg {
                            width: 4vw;
                            height: 4vh;
                        }
                    }

                    .linkName {
                        display: none;
                    }
                }
            }

            .orgData {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 100%;

                p {
                    text-align: center;
                    color: white;
                    font-weight: 700;
                }
            }
        }
    }
}

.notificationIcon {
    position: relative;
}

.notificationCount {
    position: absolute;
    top: -5px;
    // right: 81px;
    color: #fff;
    background-color: var(--primaryColor);
    border-radius: 50%;
    padding: 0 !important;
    margin: 0 !important;
    // margin: auto;
    text-align: center;
    width: 23px;
    font-size: 12px;
    font-weight: bold;
    padding: 2px 5px !important ;
}
.badge {
    background-color: #b94517;
    padding: 0.25rem 1rem;
    border-radius: 25px;
    margin-left: 1rem;
    font-weight: bold;
}

.bump {
    animation: bump 300ms ease-out;
}

@keyframes bump {
    0% {
        transform: scale(1);
    }
    10% {
        transform: scale(0.9);
    }
    30% {
        transform: scale(1.1);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}
