.content {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 6%;
    color: var(--primaryColor700);

    .image {
        width: 100%;
        padding: 10px;
    }
}