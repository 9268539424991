@media (min-width: 900px) {
    .section {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        // position: fixed;
        // overflow: hidden !important;
        // height: 100%;
        height: auto;
        min-height: 100vh;
        width: 100%;

        .content {
            width: 85%;
            // height: 100vh;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 1% 5%;
            background-color: #efefef;

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .title {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    color: var(--primaryColor700);
                    font-weight: bold;
                    width: 100%;

                    h3 {
                        margin: 0;
                    }

                    svg {
                        margin-right: 3%;

                        &:hover {
                            background-color: var(--grayColor100);
                            border-radius: 15px;
                        }
                    }
                }

                .options {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                }
            }
        }
    }
}

@media (max-width: 899px) {
    .section {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        height: auto;
        min-height: 100vh;
        width: 100%;

        .content {
            width: 85%;
            // height: 100vh;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 1% 5%;
            background-color: #f1f0f0;

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .title {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    color: var(--primaryColor700);
                    font-weight: bold;
                    width: 100%;

                    h3 {
                        margin: 0;
                        font-size: calc(0.9rem + 0.6vw);
                    }

                    svg {
                        margin-right: 3%;
                        width: 3vw;
                        height: 5vh;

                        &:hover {
                            background-color: var(--grayColor100);
                            border-radius: 15px;
                        }
                    }
                }

                .options {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                }
            }
        }
    }
}

.outlet {
    height: 100%;
    width: 100%;
}

.alertContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
}

.trialAlertHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;

    span {
        width: 30vw;
        gap: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

.trialAlertBody {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        margin: 0;
        width: 75%;
    }
}
