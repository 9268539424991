.button {
    background-color: var(--primaryColor) !important;
    color: #fff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid var(--primaryColor) !important;
    width: 100%;
    padding: 2% 0;
    border-radius: 0.375rem;
    font-weight: 500;

    &:hover {
        background-color: #fff !important;
        color: var(--primaryColor) !important;
    }
    &:disabled {
        background-color: rgb(153, 153, 153) !important;
        border-color: rgb(153, 153, 153) !important;
        color: #fff !important;
        cursor: not-allowed;
    }
}