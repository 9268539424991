.outerDiv {
    background-image: linear-gradient(162.65deg, #F6C012 28.36%, #FFFFFF 45.58%, #F6C012 83.23%);
    border-radius: 50%;
    padding: 3px !important;
    margin: 5px !important;
    min-width: 75px;
    height: 81px;

    .innerDiv {
        border: 4px solid var(--primaryColor);
        background-color: #fff;
        padding: 3px 3px !important;
        border-radius: 50%;
        max-width: 80px;
        min-height: 74px;

        img {
            width: 65px;
            max-width: 100%;
            height: 60px;
            border-radius: 50%;
            background-color: transparent;
        }
    }
}