.container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin: 0 2%;
    
    textarea {
        width: 100%;
        padding: 0.5rem 0.6rem 0.5rem 0.6rem;
        margin: 10px 0 10px 0;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: var(--primaryColor700);
        background-color: hsl(0, 0%, 100%);
        background-clip: padding-box;
        border: 1px solid hsl(0, 0%, 80%);
        appearance: none;
        border-radius: 0.375rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    
        &:focus {
            color: var(--primaryColor700);
            background-color: #fff;
            border-color: #86b7fe;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
        }

        &:hover {
            border-color: hsl(0, 0%, 70%);
        }
    }

    label {
        position: absolute;
        top: 1.5em;
        left: 0;
        font-size: 14px;
        font-weight: normal;
        color: grey;
        text-transform: capitalize;
        transition: ease-in-out 0.2s;
        cursor: text;
        padding-left: 10px !important;
    }

    .inputFocus {
        top: 1em;
        left: 0;
        font-size: 10px;
    }
}

