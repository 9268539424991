.enStyles {
    direction: ltr;
    // input{
    //     &:dir(ltr){
    //         margin: 10px;
    //         margin-left: 0;
    //         text-align: left;
    //     }
    // }
}

.arStyles {
    direction: rtl;
    // input{
    //     &:dir(rtl){
    //         margin: 10px;
    //         margin-right: 0;
    //         text-align: right;
    //         border: 1px solid red !important;
    //     }
    // }
}