.form {
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    p {
        padding: 0;
        margin: 0;
    }
    .title {
        color: var(--grayColor400);
        margin-bottom: 25px;
        text-align: center;
        padding: 0px 30px;
    }
    .cs {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 30px 20px;
        .customer {
            color: var(--primaryColor700);
            font-weight: bold;
            font-size: 20px;
        }

        .contacts {
            display: flex;
            margin-top: 20px;
            margin-bottom: 10px;
            justify-content: space-between;
            align-items: center;
            .phone {
                display: flex;
                align-items: center;
                gap: 10px;
                img{
                    &:first-of-type{
                        width: 50px;
                        height: 50px;
                    }
                }
                div{
                    p{
                        color: var(--primaryColor800);
                        font-weight: bold;
                        padding: 0;
                        margin: 0;
                        &:first-of-type{
                            color: var(--grayColor800);
                            font-weight: 600;
                        }
                    }
                 
                }
            }
         .social{
            display: flex;
            gap: 8px;
            cursor: pointer;
            align-items: center;
            img{
                width: 35px;
                height: 35px;
                &:first-of-type{
                    width: 30px;
                    height: 30px;
                }
            }
            svg{
                font-size: 20px;
                cursor: pointer;
                color: var(--primaryColor800);
            }
         }
        }

    
        .inputs{
            padding-top: 10px;
        }
    }
}
