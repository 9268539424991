.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    img{
        width: 120px;
        height: 120px;
    }

    p {
        margin: 0;
        padding-top: 1rem;
        color: var(--primaryColor700);
        font-weight: 500;
        text-align: center;
    }

    .actionBtns {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 85%;
        padding: 4% 0;
        gap: 35px;

        div {
            width: 42%;

            button {
                padding: 3% 6%;
            }
        }
    }
}
