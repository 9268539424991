.button {
    background-color: transparent;
    color: var(--primaryColor);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10%;
    border-radius: 50%;
    font-weight: 500;

    &:hover {
        background-color: var(--grayColor300);
    }

    &:disabled {
        background-color: rgb(153, 153, 153);
        border-color: rgb(153, 153, 153);
        cursor: not-allowed;
    }
}