.modal {
    overflow: hidden !important;
    direction: ltr;

    .modalHeader {
        color: var(--primaryColor) !important;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x))
                calc(-0.5 * var(--bs-modal-header-padding-y)) auto !important;
        }
    }
}

.rtlModal {
    overflow: hidden !important;
    direction: rtl !important;
    text-align: right;

    .modalHeader {
        color: var(--primaryColor) !important;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            margin: calc(-0.5 * var(--bs-modal-header-padding-y)) auto calc(-0.5 * var(--bs-modal-header-padding-y))
                calc(-0.5 * var(--bs-modal-header-padding-x)) !important;
        }
    }
}
